import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import SEO from 'components/SEO';
import Footer from 'components/Footer';
import useWindowSize from 'hooks/useWindowSize';
import { useIsRtl } from 'hooks/useCurrentLocale';
import { theme } from 'styles/styled';
import cryptocurrency from 'images/cryptocurrency.svg';
import mining from 'images/mining.svg';
import blockchain from 'images/blockchain.svg';
import miningCity from 'images/mining-city.svg';
import Breadcrumbs from 'components/Breadcrumbs';
import EducationNavigation from 'components/EducationNavigation';
import SocialShareBar from 'components/SocialShareBar';
import menuLinks from 'settings/menuLinks';
import {
  translationsFactory,
  TranslationResult,
  TranslationsContext,
  useTranslations,
} from 'settings/i18n';
import useLinkCreator from 'hooks/useLinkCreator';
import ClientOnly from 'components/ClientOnly/ClientOnly';

import {
  EducationTile,
  CollapsedEducationTile,
  EducationTileImage,
  EducationTileTitle,
  CollapsedEducationTileTitle,
  EducationTileDescription,
  EducationTileButton,
  RegisteredUsersText,
  EducationHeading,
  EducationWrapper,
} from 'components/EducationTile/EducationTile';

const MainWrapper = styled.main`
  margin: 0 auto;
  max-width: 940px;
  width: 100%;
  padding: ${theme.dimensions.spacingBase16 * 1.25}px 0;
  padding-top: 124px;

  @media ${theme.devices.medium} {
    padding-top: 176px;
  }
`;

const CollapsedEducationMiningCityTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const EducationProviders = ({ data }: { data: TranslationResult }) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.wpPage?.translation,
        ...data?.homepage?.translation,
      },
    },
  });

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={data.metadata || undefined} url={menuLinks.education} />
      <ClientOnly>
        <SocialShareBar pageTitle={data?.metadata?.seo?.title} />
        <EducationNavigation />
        <Education />
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

const Education = () => {
  const t = useTranslations();
  const createLink = useLinkCreator();

  const isRtl = useIsRtl();

  const { width } = useWindowSize();

  const isMobile = width <= 768;

  const [tile, setTile] = React.useState<
    'cryptocurrency' | 'mining' | 'blockchain' | 'mining_city'
  >('cryptocurrency');

  return (
    <MainWrapper>
      <EducationHeading
        isRtl={isRtl}
        size="heading"
        color="dark"
        weight="medium"
        letterSpacing="tight">
        {t('education').toLowerCase()}
      </EducationHeading>
      <Breadcrumbs
        links={[
          { to: createLink(menuLinks.home), name: t('home') },
          { to: createLink(menuLinks.education), name: t('education') },
        ]}
      />
      <EducationWrapper>
        {(!isMobile || (isMobile && tile === 'cryptocurrency')) && (
          <EducationTile
            onClick={isMobile ? () => setTile('cryptocurrency') : undefined}>
            <EducationTileImage
              src={cryptocurrency}
              alt={t('cryptocurrency')}
            />
            <EducationTileTitle isRtl={isRtl}>
              {t('cryptocurrency')}
            </EducationTileTitle>
            <EducationTileDescription isRtl={isRtl}>
              {t('cryptocurrencyDescription')}
            </EducationTileDescription>
            <EducationTileButton
              isRtl={isRtl}
              to={createLink(menuLinks.cryptocurrency)}>
              {t('view')}
            </EducationTileButton>
          </EducationTile>
        )}
        {isMobile && tile !== 'cryptocurrency' && (
          <CollapsedEducationTile
            onClick={isMobile ? () => setTile('cryptocurrency') : undefined}>
            <CollapsedEducationTileTitle isRtl={isRtl}>
              {t('cryptocurrency')}
            </CollapsedEducationTileTitle>
          </CollapsedEducationTile>
        )}

        {(!isMobile || (isMobile && tile === 'mining')) && (
          <EducationTile
            onClick={isMobile ? () => setTile('mining') : undefined}>
            <EducationTileImage src={mining} alt={t('mining')} />
            <EducationTileTitle isRtl={isRtl}>{t('mining')}</EducationTileTitle>
            <EducationTileDescription isRtl={isRtl}>
              {t('miningDescription')}
            </EducationTileDescription>
            <EducationTileButton
              isRtl={isRtl}
              to={createLink(menuLinks.mining)}>
              {t('view')}
            </EducationTileButton>
          </EducationTile>
        )}
        {isMobile && tile !== 'mining' && (
          <CollapsedEducationTile
            onClick={isMobile ? () => setTile('mining') : undefined}>
            <CollapsedEducationTileTitle isRtl={isRtl}>
              {t('mining')}
            </CollapsedEducationTileTitle>
          </CollapsedEducationTile>
        )}

        {(!isMobile || (isMobile && tile === 'blockchain')) && (
          <EducationTile
            style={{ alignSelf: 'start' }}
            onClick={isMobile ? () => setTile('blockchain') : undefined}>
            <EducationTileImage src={blockchain} alt={t('blockchain')} />
            <EducationTileTitle isRtl={isRtl}>
              {t('blockchain')}
            </EducationTileTitle>
            <EducationTileDescription isRtl={isRtl}>
              {t('blockchainDescription')}
            </EducationTileDescription>
            <EducationTileButton
              isRtl={isRtl}
              to={createLink(menuLinks.blockchain)}>
              {t('view')}
            </EducationTileButton>
          </EducationTile>
        )}
        {isMobile && tile !== 'blockchain' && (
          <CollapsedEducationTile
            onClick={isMobile ? () => setTile('blockchain') : undefined}>
            <CollapsedEducationTileTitle isRtl={isRtl}>
              {t('blockchain')}
            </CollapsedEducationTileTitle>
          </CollapsedEducationTile>
        )}

        {(!isMobile || (isMobile && tile === 'mining_city')) && (
          <div>
            <EducationTile
              onClick={isMobile ? () => setTile('mining_city') : undefined}>
              <EducationTileImage src={miningCity} alt={t('miningCity')} />
              <EducationTileTitle isRtl={isRtl} variant="registered-only">
                {t('miningCity')}
              </EducationTileTitle>
              <EducationTileDescription isRtl={isRtl} variant="registered-only">
                {t('miningCityDescription')}
              </EducationTileDescription>
              <EducationTileButton
                isRtl={isRtl}
                to={createLink(menuLinks.miningCity)}
                target="_blank"
                rel="noopener noreferrer">
                {t('view')}
              </EducationTileButton>
            </EducationTile>
            <RegisteredUsersText isRtl={isRtl}>
              {t('onlyForRegisteredUsers')}
            </RegisteredUsersText>
          </div>
        )}
        {isMobile && tile !== 'mining_city' && (
          <CollapsedEducationMiningCityTile>
            <CollapsedEducationTile
              onClick={isMobile ? () => setTile('mining_city') : undefined}>
              <CollapsedEducationTileTitle
                isRtl={isRtl}
                variant="registered-only">
                {t('miningCity')}
              </CollapsedEducationTileTitle>
            </CollapsedEducationTile>
            <RegisteredUsersText isRtl={isRtl} marginTop="10px">
              {t('onlyForRegisteredUsers')}
            </RegisteredUsersText>
          </CollapsedEducationMiningCityTile>
        )}
      </EducationWrapper>
    </MainWrapper>
  );
};

export default EducationProviders;

export const query = graphql`
  query($locale: String!) {
    wpPage(slug: { regex: "/\\b(\\w*education\\w*)+(-\\d+)?\\b$/" }, language: { locale: { eq: $locale } }) {
      id
      translation {
        home
        mining
        miningCity
        miningCityDescription
        miningDescription
        cryptocurrency
        cryptocurrencyDescription
        blockchainDescription
        blockchain
        view
        onlyForRegisteredUsers
      }
    }
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*education\\w*)+(-\\d+)?\\b$/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
      ...AboutTranslation
    }
  }
`;
